import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip, DatePicker } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import Add from '../mrtjjl/add';
import Dcdzd from '../mrtjjl/dcdzd';
import Jiesuan from '../mrtjjl/jiesuan';
import Djedit from './djedit';
import moment from 'moment';
const { Option } = Select;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                type: 2
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        this.props.NAV_CHANGE('/wdbmx', '外租统计')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }
    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 240 - selheight
        that.setState({
            tableHeight: yheight
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        arr.is_wz = 1 //是否为外租
        fetch('/Mrtjjl/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    sfbljs_react: data.sfbljs_react ? data.sfbljs_react : [],
                    month_react: data.month_react ? data.month_react : [],
                    sbzldw_react: data.sbzldw_react ? data.sbzldw_react : [],
                    jhid_react: data.jhid_react ? data.jhid_react : [],
                    projectid_react: data.projectid_react ? data.projectid_react : [],
                    chid_react: data.chid_react ? data.chid_react : [],
                    sgdwid_react: data.sgdwid_react ? data.sgdwid_react : [],
                    hntjbzid_react: data.hntjbzid_react ? data.hntjbzid_react : [],
                    sj_react: data.sj_react ? data.sj_react : [],
                    sblx_react: data.sblx_react ? data.sblx_react : [],
                    sbly_react: data.sbly_react ? data.sbly_react : [],
                    wdbdw_react: data.wdbdw_react ? data.wdbdw_react : [],
                })
            })
    }
    delSel() {
        let that = this
        fetch('/Mrtjjl/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }
    delSel2(id, e) {
        let that = this
        fetch('/Mrtjjl/delSel2', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }
    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Mrtjjl/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }
    refresh = () => {
        let that = this
        that.getdata()
        that.setState({
            selectedRowKeys: []
        })
    }
    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, { [name + '1']: dateStrings[0], [name + '2']: dateStrings[1] })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                type: 2
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '创建日期',
                dataIndex: 'addtime_str',
                key: 'addtime_str',
                fixed: 'left',
                width:100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '结算日期',
                dataIndex: 'wz_jsrq_str',
                key: 'wz_jsrq_str',
                fixed: 'left',
                width:100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '外租泵回票日期',
                dataIndex: 'wzbhprq_str',
                key: 'wzbhprq_str',
                ellipsis: {
                    showTitle: false,
                },
                fixed: 'left',
                width:120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '生产日期',
                dataIndex: 'scrq_str',
                key: 'scrq_str',
                fixed:'left',
                width:100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '客户',
                dataIndex: 'sbzldw_str',
                key: 'sbzldw_str',
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                width:150,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },

            {
                title: '计划名称',
                dataIndex: 'jhmc',
                key: 'jhmc',
                ellipsis: {
                    showTitle: false,
                },
                fixed:'left',
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '外租泵单位',
                dataIndex: 'wdbdw',
                key: 'wdbdw',
                ellipsis: {
                    showTitle: false,
                },
                fixed:'left',
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '类型',
                dataIndex: 'wdsblx_str',
                key: 'wdsblx_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 60,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '生产时间',
                dataIndex: 'scsj',
                key: 'scsj',
                ellipsis: {
                    showTitle: false,
                },
                width:80,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '计划方量',
                dataIndex: 'jhfl',
                key: 'jhfl',
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '外租方量',
                dataIndex: 'wzfl',
                key: 'wzfl',
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '外租台班',
                dataIndex: 'wztb',
                key: 'wztb',
                ellipsis: {
                    showTitle: false,
                },
                width:80,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '单价',
                dataIndex: 'dj',
                key: 'dj',
                ellipsis: {
                    showTitle: false,
                },
                width:60,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '外租单价',
                dataIndex: 'wzdj',
                key: 'wzdj',
                ellipsis: {
                    showTitle: false,
                },
                width:80,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '外租金额',
                dataIndex: 'wzje',
                key: 'wzje',
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '项目名称',
                dataIndex: 'xmmc',
                key: 'xmmc',
                ellipsis: {
                    showTitle: false,
                },
                // width:200,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '结款状态',
                dataIndex: 'jkzt_str',
                key: 'jkzt_str',
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 120,
                fixed: 'right',
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                {store.getState().data.info.qx2 ? (store.getState().data.info.qx2 == 1 ?
                                    <Add id={record.id} refresh={this.getdata.bind(this)} nolast={1} />
                                    : null) : null}
                            </Space>
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll: true,
            preserveSelectedRowKeys: false,
            // renderCell: function (checked, record, index, originNode) {
            //     if (record.status != 1) {
            //         return originNode
            //     } else {
            //         return false
            //     }
            // },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" align="top" >
                        <Space wrap style={{ width: 'calc(100% - 200px)' }}>
                            {/*<DatePicker style={{ width: '100%' }} placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null} allowClear={false} />
                            <Select placeholder="月份" style={{ width: 120 }} value={this.state.newcondition.month ? this.state.newcondition.month : 0} onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                {this.arrsel(this.state.month_react)}
                            </Select>*/}
                            <RangePicker allowEmpty={[true, true]} picker="month" onChange={this.onRangeChange.bind(this, 'mon_addtime')} value={[(this.state.newcondition.mon_addtime1 ? moment(this.state.newcondition.mon_addtime1) : null), (this.state.newcondition.mon_addtime2 ? moment(this.state.newcondition.mon_addtime2) : null)]} placeholder={['创建日期(月)', '创建日期(月)']} />
                            <Select placeholder="是否办理结算" value={this.state.newcondition.wz_sfbljs ? this.state.newcondition.wz_sfbljs : null} onChange={this.onSelChange.bind(this, 'wz_sfbljs')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sfbljs_react)}
                            </Select>
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'wz_jsrq')} value={[(this.state.newcondition.wz_jsrq1 ? moment(this.state.newcondition.wz_jsrq1) : null), (this.state.newcondition.wz_jsrq2 ? moment(this.state.newcondition.wz_jsrq2) : null)]} placeholder={['结算日期', '结算日期']} />
                            <Select placeholder="客户" value={this.state.newcondition.sbzldw ? this.state.newcondition.sbzldw : null} onChange={this.onSelChange.bind(this, 'sbzldw')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sbzldw_react)}
                            </Select>
                            {/* <Select placeholder="计划名称" value={this.state.newcondition.jhid ? this.state.newcondition.jhid : null} onChange={this.onSelChange.bind(this, 'jhid')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.jhid_react)}
                            </Select> */}
                            <Input placeholder="计划名称" value={this.state.newcondition.jhmc ? this.state.newcondition.jhmc : null} onChange={this.onChange.bind(this, 'jhmc')}  style={{ width: 200 }} />
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'scrq')} value={[(this.state.newcondition.scrq1 ? moment(this.state.newcondition.scrq1) : null), (this.state.newcondition.scrq2 ? moment(this.state.newcondition.scrq2) : null)]} placeholder={['生产日期', '生产日期']} />
                            <Select placeholder="项目名称" value={this.state.newcondition.projectid ? this.state.newcondition.projectid : null} onChange={this.onSelChange.bind(this, 'projectid')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.projectid_react)}
                            </Select>
                            <Select placeholder="车辆编号" value={this.state.newcondition.chid ? this.state.newcondition.chid : null} onChange={this.onSelChange.bind(this, 'chid')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.chid_react)}
                            </Select>
                            <Select placeholder="外租泵单位" value={this.state.newcondition.wdbdw ? this.state.newcondition.wdbdw : null} onChange={this.onSelChange.bind(this, 'wdbdw')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.wdbdw_react)}
                            </Select>
                            <Select placeholder="施工单位" value={this.state.newcondition.sgdwid ? this.state.newcondition.sgdwid : null} onChange={this.onSelChange.bind(this, 'sgdwid')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sgdwid_react)}
                            </Select>
                            <Select placeholder="混凝土搅拌站" value={this.state.newcondition.hntjbzid ? this.state.newcondition.hntjbzid : null} onChange={this.onSelChange.bind(this, 'hntjbzid')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.hntjbzid_react)}
                            </Select>
                            <div className="site-input-group-wrapper">
                                <Input.Group compact>
                                    <Input
                                        style={{
                                            width: 100,
                                            textAlign: 'center',
                                            borderRight: 0,
                                        }}
                                        type="text"
                                        placeholder="金额"
                                        value={this.state.newcondition.je1 ? this.state.newcondition.je1 : null}
                                        onChange={this.onChange.bind(this, 'je1')}
                                    />
                                    <Input
                                        className="site-input-split"
                                        style={{
                                            width: 30,
                                            borderLeft: 0,
                                            borderRight: 0,
                                            pointerEvents: 'none',
                                        }}
                                        placeholder="~"
                                        disabled
                                    />
                                    <Input
                                        className="site-input-right"
                                        style={{
                                            width: 100,
                                            textAlign: 'center',
                                        }}
                                        type="text"
                                        placeholder="金额"
                                        value={this.state.newcondition.je2 ? this.state.newcondition.je2 : null}
                                        onChange={this.onChange.bind(this, 'je2')}
                                    />
                                </Input.Group>
                            </div>
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'wzbhprq')} value={[(this.state.newcondition.wzbhprq1 ? moment(this.state.newcondition.wzbhprq1) : null), (this.state.newcondition.wzbhprq2 ? moment(this.state.newcondition.wzbhprq2) : null)]} placeholder={['外租泵回票日期', '外租泵回票日期']} />
                            <div className="site-input-group-wrapper">
                                <Input.Group compact>
                                    <Input
                                        style={{
                                            width: 100,
                                            textAlign: 'center',
                                            borderRight: 0,
                                        }}
                                        type="text"
                                        placeholder="结算方量"
                                        value={this.state.newcondition.jsfl1 ? this.state.newcondition.jsfl1 : null}
                                        onChange={this.onChange.bind(this, 'jsfl1')}
                                    />
                                    <Input
                                        className="site-input-split"
                                        style={{
                                            width: 30,
                                            borderLeft: 0,
                                            borderRight: 0,
                                            pointerEvents: 'none',
                                        }}
                                        placeholder="~"
                                        disabled
                                    />
                                    <Input
                                        className="site-input-right"
                                        style={{
                                            width: 100,
                                            textAlign: 'center',
                                        }}
                                        type="text"
                                        placeholder="结算方量"
                                        value={this.state.newcondition.jsfl2 ? this.state.newcondition.jsfl2 : null}
                                        onChange={this.onChange.bind(this, 'jsfl2')}
                                    />
                                </Input.Group>
                            </div>
                            <div className="site-input-group-wrapper">
                                <Input.Group compact>
                                    <Input
                                        style={{
                                            width: 100,
                                            textAlign: 'center',
                                            borderRight: 0,
                                        }}
                                        type="text"
                                        placeholder="结算金额"
                                        value={this.state.newcondition.jsje1 ? this.state.newcondition.jsje1 : null}
                                        onChange={this.onChange.bind(this, 'jsje1')}
                                    />
                                    <Input
                                        className="site-input-split"
                                        style={{
                                            width: 30,
                                            borderLeft: 0,
                                            borderRight: 0,
                                            pointerEvents: 'none',
                                        }}
                                        placeholder="~"
                                        disabled
                                    />
                                    <Input
                                        className="site-input-right"
                                        style={{
                                            width: 100,
                                            textAlign: 'center',
                                        }}
                                        type="text"
                                        placeholder="结算金额"
                                        value={this.state.newcondition.jsje2 ? this.state.newcondition.jsje2 : null}
                                        onChange={this.onChange.bind(this, 'jsje2')}
                                    />
                                </Input.Group>
                            </div>
                            <Select placeholder="外租设备类型" value={this.state.newcondition.sblx ? this.state.newcondition.sblx : null} onChange={this.onSelChange.bind(this, 'sblx')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sblx_react)}
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                        {store.getState().data.info.qx2 ? (store.getState().data.info.qx2 == 1 ?
                            // <Space wrap>
                            //     <Add refresh={this.getdata.bind(this)} setinfo={this.props.SET_INFO} />
                            //     <Button type="primary" className='blue'>导出</Button>
                            // </Space>
                            <div style={{ maxWidth: 200 }}>
                                <Space wrap gutter={[6, 6]} style={{ justifyContent: 'flex-end' }}>
                                    <Dcdzd type={2} />
                                </Space>
                            </div>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    rowSelection={rowSelection}
                    scroll={{ x: 2000, y: this.state.tableHeight ? this.state.tableHeight : null }}
                    className='table'
                    size="small"
                    summary={(pageData) => {
                        let totalfl = 0;
                        let totalgzfl = 0;
                        let totaldj = 0;
                        let totalje = 0;
                        let totaljsfl = 0;
                        let totaljsdj = 0;
                        let totaljsje = 0;
                        let totaljsflcy = 0;
                        let totaljsdjcy = 0;
                        let totaljsjecy = 0;
                        let totaljhfl = 0;
                        let totalwzfl = 0;
                        let totalwzje = 0;
                        pageData.forEach(({ fl, gzfl, dj, je, jsfl, jsdj, jsje, jsflcy, jsdjcy, jsjecy,jhfl,wzfl,wzje }) => {
                            totalfl += Number(fl ? fl : 0);
                            totalgzfl += Number(gzfl ? gzfl : 0);
                            totaldj += Number(dj ? dj : 0);
                            totalje += Number(je ? je : 0);
                            totaljsfl += Number(jsfl ? jsfl : 0);
                            totaljsdj += Number(jsdj ? jsdj : 0);
                            totaljsje += Number(jsje ? jsje : 0);
                            totaljsflcy += Number(jsflcy ? jsflcy : 0);
                            totaljsdjcy += Number(jsdjcy ? jsdjcy : 0);
                            totaljsjecy += Number(jsjecy ? jsjecy : 0);
                            totaljhfl += Number(jhfl ? jhfl : 0);
                            totalwzfl += Number(wzfl ? wzfl : 0);
                            totalwzje += Number(wzje ? wzje : 0);
                        });
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}>{totaljhfl.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}>{totalwzfl.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={14}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}>{totalwzje.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={16}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={17}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={18}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={19}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
                <div className='page page3'>
                    <div className='l'>
                        <Space wrap>
                            {hasSelected ?
                                <>
                                    {store.getState().data.info.qx2 ? (store.getState().data.info.qx2 == 1 ?
                                        <>
                                            <Jiesuan refresh={this.getdata.bind(this)} arrid={selectedRowKeys} type={2} />
                                            <Djedit refresh={this.getdata.bind(this)} arrid={selectedRowKeys} />
                                        </>
                                        : null) : null}
                                </>
                                : null}
                        </Space>
                    </div>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div>
            </>
        )
    }
}
